body {
    margin: 0;
    --accent-color: #e3000f;
    font-family: 'MuseoSansCyrl', 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('./assets/fonts/MuseoSansCyrl-500.eot');
    src: local('MuseoSansCyrl-500'),
        url('./assets/fonts/MuseoSansCyrl-500.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/MuseoSansCyrl-500.woff') format('woff'),
        url('./assets/fonts/MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('./assets/fonts/MuseoSansCyrl-700.eot');
    src: local('MuseoSansCyrl-700'),
        url('./assets/fonts/MuseoSansCyrl-700.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/MuseoSansCyrl-700.woff') format('woff'),
        url('./assets/fonts/MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('./assets/fonts/MuseoSansCyrl-300.eot');
    src: local('MuseoSansCyrl-300'),
        url('./assets/fonts/MuseoSansCyrl-300.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/MuseoSansCyrl-300.woff') format('woff'),
        url('./assets/fonts/MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'MuseoSansCyrl';
    src: url('./assets/fonts/MuseoSansCyrl-100.eot');
    src: local('MuseoSansCyrl-100'),
        url('./assets/fonts/MuseoSansCyrl-100.eot?#iefix')
            format('embedded-opentype'),
        url('./assets/fonts/MuseoSansCyrl-100.woff') format('woff'),
        url('./assets/fonts/MuseoSansCyrl-100.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
